import React, { useState, useEffect } from "react";
import "../../Assets/css/home.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
// import '../../../node_modules/swiper/swiper-bundle.min';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";

import roundD from "../../Assets/images/front/diamond-shape/roundW.webp";
import princessD from "../../Assets/images/front/diamond-shape/princessW.webp";
import cushionD from "../../Assets/images/front/diamond-shape/cushionW.webp";
import emeraldD from "../../Assets/images/front/diamond-shape/emeraldW.webp";
import ovalD from "../../Assets/images/front/diamond-shape/ovalW.webp";
import radiantD from "../../Assets/images/front/diamond-shape/radiantW.webp";
import asscherD from "../../Assets/images/front/diamond-shape/asscherW.webp";
import marquiseD from "../../Assets/images/front/diamond-shape/marquiseW.webp";
import HeartD from "../../Assets/images/front/diamond-shape/HeartW.webp";
import pearD from "../../Assets/images/front/diamond-shape/pearW.webp";

// mobile view images
import Round from "../../Assets/images/front/diamond-shape/Round.webp";
import Princess from "../../Assets/images/front/diamond-shape/Princess.webp";
import Emerald from "../../Assets/images/front/diamond-shape/Emerald.webp";
import Oval from "../../Assets/images/front/diamond-shape/Oval.webp";
import Radiant from "../../Assets/images/front/diamond-shape/Radiant.webp";
import Asscher from "../../Assets/images/front/diamond-shape/Asscher.webp";
import Marquise from "../../Assets/images/front/diamond-shape/Marquise.webp";
import Heart from "../../Assets/images/front/diamond-shape/Heart.webp";
import Pear from "../../Assets/images/front/diamond-shape/Pear.webp";



SwiperCore.use([Autoplay, EffectCoverflow]);

export default function SwiperCoverflow() {
    var history = useHistory();
    const shopbyshape = (value) => {
        var data1 = {
            shape: value,
        };
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push("/diamonds");
        window.scrollTo(0, 0);
    };

    return (
        <section className="dfl_shop_diamond_shape_section">
            <div className="title-box2 text-center">
                <h2 className="title24 dark play-font font-italic">
                    Shop Diamonds By Shape
                </h2>
            </div>

            <div className="dfl_swiper_coverflow">
                {/* desktop view  */}
                <div className="dfl_shop_by_shape_desktop">
                    <Swiper
                        effect="coverflow"
                        centeredSlides={true}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 50,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        // autoplay={{
                        //     delay: 2000,
                        //     disableOnInteraction: false
                        // }}
                        // modules={[Autoplay]}
                        slidesPerView={6}
                        breakpoints={{
                            // when window width is >= 640px
                            300: {
                                width: 400,
                                slidesPerView: 1,
                            },
                            // when window width is >= 768px

                            768: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1300: {
                                slidesPerView: 6,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Round")}>
                                <img src={roundD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Princess")}>
                                <img src={princessD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Cushion")}>
                                <img src={cushionD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Emerald")}>
                                <img src={emeraldD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Oval")}>
                                <img src={ovalD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Radiant")}>
                                <img src={radiantD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Asscher")}>
                                <img src={asscherD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Marquise")}>
                                <img src={marquiseD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Heart")}>
                                <img src={HeartD} alt="" />
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button className="btn" onClick={() => shopbyshape("Pear")}>
                                <img src={pearD} alt="" />
                            </button>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* mobile view  */}
                <div className="dfl_shop_by_shape_mobile_view">
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: false,
                        }}
                        breakpoints={{
                            // when window width is >= 640px
                            300: {
                                slidesPerView: 3,
                            },
                            // when window width is >= 768px

                            768: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Round")}
                            >
                                <div className="">
                                    <img src={Round} alt="" />
                                    <h5> Round</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                     

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Princess")}
                            >
                                <div className="">
                                    <img src={Princess} alt="" />
                                    <h5> Princess</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Emerald")}
                            >
                                <div className="">
                                    <img src={Emerald} alt="" />
                                    <h5> Emerald</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Oval")}
                            >
                                <div className="">
                                    <img src={Oval} alt="" />
                                    <h5> Oval</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Radiant")}
                            >
                                <div className="">
                                    <img src={Radiant} alt="" />
                                    <h5> Radiant</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Asscher")}
                            >
                                <div className="">
                                    <img src={Asscher} alt="" />
                                    <h5> Asscher</h5>
                                </div>
                            </button>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Marquise")}
                            >
                                <div className="">
                                    <img src={Marquise} alt="" />
                                    <h5> Marquise</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Heart")}
                            >
                                <div className="">
                                    <img src={Heart} alt="" />
                                    <h5> Heart</h5>
                                </div>
                            </button>
                        </SwiperSlide>

                        <SwiperSlide>
                            <button
                                className="btn dfl_shopByShap_swiper_box"
                                onClick={() => shopbyshape("Pear")}
                            >
                                <div className="">
                                    <img src={Pear} alt="" />
                                    <h5> Pear</h5>
                                </div>
                            </button>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}
