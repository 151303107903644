import React from 'react';
import "../../Assets/css/home.css";
import { Link  } from 'react-router-dom';
import { Button, Col,  Row } from 'react-bootstrap';
// import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import DiamondImg1 from "../../Assets/images/front/diamond_screen.webp";
import DiamondImg3 from "../../Assets/images/front/pendaant_diamonds.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DiamondCollectionVideo = () => {
    return (
        <>

            <div className="diamond_video12">
                <div className="clearfix">
                    <div className="diamond_video">

                        <Row className='flex-xs-column-reverse'>
                            <Col lg={6}>
                                <div className="qode_video_box">

                                    {/* <Button to="" className="qode_video_image popup-vimeo" onClick={() => setOpen(true)}>
                                        <img src={DiamondImg1} alt="diamond" />
                                        <span className="qode_video_box_button_holder" >
                                            <span className="qode_video_box_button">
                                                <span className="qode_video_box_button_arrow">
                                                </span>
                                            </span>
                                        </span>
                                    </Button> */}

                                    <Button  className="qode_video_image popup-vimeo" >
                                      <Link to="/diamonds"> <LazyLoadImage src={DiamondImg1} alt="diamond" /> </Link>  
                                        {/* <span className="qode_video_box_button_holder" >
                                            <span className="qode_video_box_button">
                                                <span className="qode_video_box_button_arrow">
                                                </span>
                                            </span>
                                        </span> */}
                                    </Button>
                                </div>

                                {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)} /> */}

                            </Col>
                            <Col lg={4}>
                                <div className="content_section">
                                <LazyLoadImage  className="Dfl_dc_img" alt="diamond" src={DiamondImg3} />
                                    <h2>Diamond Collection</h2>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>


         



        </>
    )
}

export default DiamondCollectionVideo;