import React from "react";
import "../../Assets/css/home.css";
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import D_ring from "../../Assets/images/front/setting.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DiamondRing = () => {
  return (
    <>
      <section className="dfl_diamond_ring_section">
        <Container className="rcs_custome_container1">
          <div className=" video-section1">
            <Row>
              <Col lg={6} className="create_diamond_section">
                <h1>D</h1>
                <div className="create_diamond_section_inner">
                  <h6>Create your Own </h6>
                  <h4>Diamond Rings</h4>
                  <p className="title16">
                    {" "}
                    Allow the "Medallion Jewelers" experts choose the perfect
                    diamond. Stop the confusing endless online search for a
                    diamond and let us guide you buy the best diamond.{" "}
                  </p>
                  {/* <p className="title16">Select a setting and choose a diamond to create your own diamond engagement ring. </p> */}
                  <p>
                    <b>Need help?</b> We're always here.
                  </p>
                  <NavLink to="/ringsettings" className="start_btn">
                    Start with a setting
                  </NavLink>
                  <br />
                  <NavLink to="/diamonds" className="start_btn">
                    Start with a diamond
                  </NavLink>
                </div>
              </Col>
              <Col lg={6}>
                <div className="text-center">
                  <LazyLoadImage  className="vert-move" alt="Diamond Engagement Ring" src={D_ring} />
                  {/* <img
                    src={D_ring}
                    alt="Diamond Engagement Ring"
                    className="vert-move"
                  /> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DiamondRing;
