import React from "react";
import "../../Assets/css/home.css";
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Ex1 from "../../Assets/images/front/engagement_col.webp";
import eng from "../../Assets/images/engagement/engagement_ring.png";
import Ex2 from "../../Assets/images/front/necklace_col.webp";
import Ex3 from "../../Assets/images/front/wedding_rings_col.webp";
import Ex4 from "../../Assets/images/front/earrings_col.webp";
import Ex5 from "../../Assets/images/front/bracelet_col.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExploreCollectionDfl = () => {
  return (
    <>
      <section className="dfl_diamond_exp_section">
        <Container className="rcs_custome_container">
          <Row>
            <Col>
              <div className="sec-category">
                <div className="title-box2 text-center">
                  <h2 className="title30 dark">Explore Our Collection</h2>
                </div>

                <div className="container sec-category1">
                  <div className="clearfix">
                    <div className="collection_section">
                      <div className="collection_section_content">
                        <Row className="flex-xs-column-reverse">
                          <Col lg={5}>
                            <div className="create_diamond_section">
                              <h2 className="bgText">E</h2>
                              <div className="create_diamond_section_inner">
                                <h6>Rings </h6>
                                <h4>
                                  Engagement <br /> Rings
                                </h4>
                                <p className="title16">
                                  Select your perfect Engagement Ring with
                                  diamond. Choose from wide range of designer
                                  rings.
                                </p>
                                <NavLink
                                  to="/ringsettings"
                                  className="start_btn"
                                >
                                  Shop Now
                                </NavLink>
                              </div>
                            </div>
                          </Col>

                          <Col lg={7}>
                            <div className="create_diamond_img pull-right">
                              <NavLink to="/ringsettings" className="image_url">
                                <LazyLoadImage
                                  src={eng}
                                  alt="Engagement Rings"
                                />
                              </NavLink>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="collection_section">
                      <div className="collection_section_content">
                        <Row>
                          <Col lg={7}>
                            <div className="create_diamond_img pull-right">
                              <NavLink to="/ringsettings" className="image_url">
                                <LazyLoadImage
                                  src={Ex1}
                                  alt="Engagement Rings"
                                />
                              </NavLink>
                            </div>
                          </Col>

                          <Col lg={5}>
                            <div className="create_diamond_section">
                              <h2 className="bgText">N</h2>
                              <div className="create_diamond_section_inner">
                                <h6>Necklace </h6>
                                <h4>Necklaces</h4>
                                <p className="title16">
                                  Find the perfect length for every occasion
                                  with classic pearl strands, diamond elegance,
                                  colorful gemstones and more!
                                </p>
                                <NavLink
                                  to="/jewelry/necklaces"
                                  className="start_btn"
                                >
                                  Shop Now
                                </NavLink>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="collection_section">
                      <div className="collection_section_content">
                        <Row className="flex-xs-column-reverse">
                          <Col lg={5}>
                            <div className="create_diamond_section">
                              <h2 className="bgText">W</h2>
                              <div className="create_diamond_section_inner">
                                <h6>Rings </h6>
                                <h4>Wedding Rings</h4>
                                <p className="title16">
                                  Find the perfect symbol of your love. Discover
                                  eternity diamond rings, classic bands, and
                                  unique styles.
                                </p>
                                <NavLink
                                  to="/jewelry/wedding-rings"
                                  className="start_btn"
                                >
                                  Shop Now
                                </NavLink>
                              </div>
                            </div>
                          </Col>
                          <Col lg={7}>
                            <div className="create_diamond_img pull-right">
                              <NavLink
                                to="/jewelry/wedding-rings"
                                className="image_url"
                              >
                                <LazyLoadImage src={Ex3} alt="Earrings" />
                              </NavLink>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="collection_section">
                      <div className="collection_section_content">
                        <Row>
                          <Col lg={7}>
                            <div className="create_diamond_img pull-right">
                              <NavLink
                                to="/jewelry/earrings"
                                className="image_url"
                              >
                                <LazyLoadImage src={Ex4} alt="Earrings" />
                              </NavLink>
                            </div>
                          </Col>

                          <Col lg={5}>
                            <div className="create_diamond_section">
                              <h2 className="bgText">E</h2>
                              <div className="create_diamond_section_inner">
                                <h6>Earrings </h6>
                                <h4>Earrings</h4>
                                <p className="title16">
                                  Look your best every day!{" "}
                                </p>
                                <p className="title16">
                                  Explore our exciting varieties of stylish
                                  earrings from short and sweet to long and
                                  dramatic.
                                </p>
                                <NavLink
                                  to="/jewelry/earrings"
                                  className="start_btn"
                                >
                                  Shop Now
                                </NavLink>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="clearfix">
                    <div className="collection_section">
                      <div className="collection_section_content">
                        <Row className="flex-xs-column-reverse">
                          <Col lg={5}>
                            <div className="create_diamond_section">
                              <h2 className="bgText">B</h2>
                              <div className="create_diamond_section_inner">
                                <h6>Bracelets </h6>
                                <h4>Bracelets</h4>
                                <p className="title16">
                                Adorn your wrist with perfection and complement every style with feminine fashion, bangle, and diamond bracelets.
                                </p>
                                <NavLink
                                  to="/jewelry/bracelets"
                                  className="start_btn"
                                >
                                  Shop Now
                                </NavLink>
                              </div>
                            </div>
                          </Col>
                          <Col lg={7}>
                          <div className="create_diamond_img pull-right">
                            <NavLink
                              to="/jewelry/bracelets"
                              className="image_url"
                            >
                              <LazyLoadImage src={Ex5} alt="bracelet" />
                            </NavLink>
                          </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ExploreCollectionDfl;
