import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import Home from './Containers/Front/Home';
import React from 'react';
import { Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { base_url,  isLogin, postHeader } from './Helpers/request';
import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
const Mainrouter = React.lazy(() => import('./mainrouter'));
const GotoTop = React.lazy(() => import('./Containers/Pages/Static/GotoTop'));
const Header = React.lazy(() => import('./Containers/Header/Header'));
const Footer = React.lazy(() => import('./Containers/Footer/footer'));
const App = () => {
  const [currencydata, setCurrencydata] = useState([]);
  const [menudata, setMenudata] = useState([]);

  useLayoutEffect(() => {
    if (localStorage.getItem("bw-session-id")?.length || isLogin) {
      console.log("session exist!")
    } else {
      axios.get(base_url + '/home/getsessionid')
        .then(res => {
          if (res.data.status == 1) {
            localStorage.setItem("bw-session-id", res.data.data?.session_id)
          } else {
            // toast.error(res.message,{autoClose: 3000});
            console.log("sessionid not generated")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    //-----------------------------------menu
    axios.get(base_url + '/home/menu', {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setMenudata(response.data.data);
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //-----------------------------------Banner
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
      // currency api
      axios.get(base_url + '/common/currency')
        .then(res => {
            if (res.data.status == 1) {
                setCurrencydata(res.data.data);
                localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : localStorage.setItem("bw-currency", res.data.data[0]?.currency_code);
                localStorage.getItem("bw-currency_img") ? localStorage.getItem("bw-currency_img") : localStorage.setItem("bw-currency_img", res.data.data[0]?.country_flag);
                localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : localStorage.setItem("bw-currencyicon", res.data.data[0]?.currency_icon);
               
            } else {
                console.log(res.data.message)
            }
        })
        .catch(err => {
            console.log(err)
        })
  }, []);  

return (
      <>
        <BrowserRouter>
        <Suspense fallback={<><Skeltonheader/></>}>
          <Header menudata={menudata} currencydata={currencydata} />
        </Suspense>
        <Switch>
          <Route exact path="/" component={Home} />
          <Suspense fallback={<><Skelton /></>}>
            <Route path='*' exact={true} component={Mainrouter} />
          </Suspense>
        </Switch>
  
        <Suspense fallback={<><Skelton /></>}>
          <Footer />
          <GotoTop />
        </Suspense>
      </BrowserRouter>
      </>
    )
}
const Skelton = () => {
  return (<>
    <div className='sk' style={{margin: "15px 0px"}}>
      <Skeleton variant="text" animation="wave"  />
    </div>
  </>)
}
const Skeltonheader = () => {
  return (<>
    <div className='skh' style={{margin: "0px 0px 10px 0px"}}>
       <Skeleton variant="text" animation="wave"  height={75} />
    </div>
  </>)
}
export default App;

