import React from 'react';
import { Container, Row } from 'react-bootstrap';
import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
            <Container>
                <Row>
                    <div className="rcs_news_content1 w-100 mt-5 mb-3" >
                        <h2 className='text-center'>Get a Tour of our Social Media</h2>
                    </div>
                </Row>
            </Container>
            {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
            <iframe title='insta' src="https://instagram.demobw.live/medallionjewelers/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </>
    )
}

export default Insta;