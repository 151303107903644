import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";

const BannerSlider = () => {
  const history = useHistory();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const banner = localStorage.getItem("bw-bannerdata")
    ? JSON.parse(localStorage.getItem("bw-bannerdata"))
    : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;

  return (
    <>
      <Slider className="rcs_slider_img banner-slider banner-container" {...settings}>
        {Array(1)
          .fill()
          .map((val, index) => (
            <div key={index}>
            
                <div>
                <div className="banner-thumb">
                <img
                  src="https://venice.demobw.com/assets/images/home/frontslider/3.jpg"
                  alt=""
                />
                </div>

                <div className="banner-info animated text-right">
                    <div className="container slider_extra_1">
                      <h2 className="title48 font-normal dark">
                      Custom Design
                      </h2>
                      <p className="title14 hide-mob">
                Jewelry that matches your vibe and is a true reflection of your personality.
                      </p>
                      <Link to="/custom-design" className="btn white title18">Let's Design</Link>
                    </div>
                </div>
                
                </div>
            
            </div>
          ))}
      </Slider>
      {/* {banner?.map((val, index) => (
        <div
          className="rcs_hero_slider"
          style={{
            backgroundImage: `url(${
              isMobileOnly ? val?.mobile_image : val?.image
            })`,
          }}
        >
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className="m-auto w-100">
                <Col md={9} className="m-auto">
                  <div
                    className={
                      isMobile ? "text-center" : val.text_direction_class
                    }
                  >
                    <h1 data-aos="fade-up-left" data-aos-duration="700">
                      {val.title}
                    </h1>
                    <p
                      data-aos="fade-up-left"
                      data-aos-duration="1200"
                      data-aos-delay="300"
                    >
                      {val.short_text}
                    </p>
                    <Button
                      data-aos="fade-up-left"
                      data-aos-duration="1800"
                      data-aos-delay="400"
                      onClick={() => {
                        history.push("/" + val.btn_link);
                      }}
                      variant="outline-dark"
                    >
                      {val.btn_text}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ))} */}
    </>
  );
};

export default BannerSlider;
