import React from 'react';
import { Container, NavLink } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';
import google_icon from "../../Assets/images/google_icon.svg";
import rcs_quote_new from "../../Assets/images/home/quote.png";
import yelp_icon from "../../Assets/images/home/yelp.png";
import dummy_user from "../../Assets/images/home/dummy_user.png";
import { useHistory } from 'react-router-dom';

const Testimonial = () => {
    const history = useHistory();
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="rcs_testimonials">
                <Container>
                    <h5 className="text-center mb100"> What People Say </h5>
                    <div className="wrapperTestimonial position-relative">
                    <Slider className="rcs_testimonial_inner" {...settings2}>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img className="google_rev_img" src={google_icon} alt="" /> </NavLink>
                            <img className="rcs_quote" src={rcs_quote_new} alt="" />
                            <p>Amazing service. I saw a beautiful, unique ring in the window that was a perfect gift for my best friend’s birthday while walking my dog. They allowed the puppy in with me, gave me a great deal, and included a lovely box. Thank you! Will be back.
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt='' className="rcs_usercircle" src="https://lh3.googleusercontent.com/a-/AD5-WCkhkhSGUxBktmjGxgfAAKjv5r3rS_KY16-r5UZ4tw=s40-c-c0x00000000-cc-rp-mo-br100" />
                                <div className="rcs_testi_user"> <h4>Kristen D</h4> </div> </NavLink>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="google_rev_img" src={google_icon} /> </NavLink>
                            <img alt="" className="rcs_quote" src={rcs_quote_new} />
                            <p>I trusted Medallion with my most cherished heirloom jewelry, my grandmother's wedding ring. After 70 years, it needed resizing and cleaning. In their care, they discovered that three small diamonds had chips... </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="rcs_usercircle" src="https://lh3.googleusercontent.com/a-/AD5-WCk7d7ZTB0hZeyklVE2BJAgtxQATRX4EU8JOXNmSMw=s40-c-c0x00000000-cc-rp-mo-ba3-br100" />
                                <div className="rcs_testi_user"> <h4>Kim Kasabian</h4> </div> </NavLink>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                            <img alt="" className="google_rev_img" src={google_icon} /> </NavLink>
                            <img alt="" className="rcs_quote" src={rcs_quote_new} />
                            <p>My fiancée bought me this beautiful genuine 1 karat opal set in a rose gold band. The ring is a vintage piece from the 1930s and is absolutely stunning. Upon going back to resize the piece, I bought a band for my partner...
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="rcs_usercircle" src="https://lh3.googleusercontent.com/a-/AD5-WCmluVKCEKn0YBenNpkAftLVUM8lexQCN_xmGc_aUQ=s40-c-c0x00000000-cc-rp-mo-br100" />
                            </NavLink>
                            <div className="rcs_testi_user"> <h4>Amanda Lovgren</h4> </div>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="google_rev_img" src={google_icon} /> </NavLink>
                            <img alt="" className="rcs_quote" src={rcs_quote_new} />
                            <p>I had 2 rings resized and 1 ring reset. They did an amazing job. I was under some time restraints due to travel and they were very accommodating about expediting and even mailing it to my destination if necessary. The customer service was superb. Highly recommend!
                            </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="rcs_usercircle" src={dummy_user} /> </NavLink>
                            <div className="rcs_testi_user"> <h4>Lynne A</h4> </div>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/medallion-jewelers-portland-2#reviews", "_blank")} to='#'>
                                <img alt="" className="google_rev_img" src={yelp_icon} /> </NavLink>
                            <img alt="" className="rcs_quote" src={rcs_quote_new} />
                            <p>I had the pleasure of working with Alex on my engagement ring and I couldn't be more happy with the results.  He made the transaction very easy, and was in communication with us throughout the entire process.
  </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.yelp.com/biz/medallion-jewelers-portland-2#reviews", "_blank")} to='#'>
                                <img alt="" className="rcs_usercircle" src="https://s3-media0.fl.yelpcdn.com/photo/e4dHNBm0RTZTk1BNFdFDuw/60s.jpg" />
                                <div className="rcs_testi_user"> <h4>Lauren C.</h4> </div> </NavLink>
                        </div>
                        <div className="rcs_testi_item">
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="google_rev_img" src={google_icon} /> </NavLink>
                            <img alt="" className="rcs_quote" src={rcs_quote_new} />
                            <p>Fantastic service. I came in with a 1 day request on a fitting for a sun stone. While his jewler didn't have that very specific cut in that size, it wasn't a big deal because He a great selection of Sunstoned in different sizes with a classic ring that I loved... </p>
                            <div className="rcs_test_star">
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                                <StarIcon />
                            </div>
                            <NavLink onClick={() => window.open("https://www.google.com/search?q=medallion+jewelers+Portland%2C+OR&oq=medallion+jewelers+Portland%2C+OR&aqs=chrome.0.69i59j0i512j0i22i30j0i390l2.2936j0j7&sourceid=chrome&ie=UTF-8#lrd=0x54950a05c028f921:0x12adbf8e2fc60f7b,1", "_blank")} to='#'>
                                <img alt="" className="rcs_usercircle" src="https://lh3.googleusercontent.com/a/AEdFTp7Y7pZFofMsn36jN1DXOT9QybtJzUhg2XzN3yJ0=s40-c-c0x00000000-cc-rp-mo-br100" /> </NavLink>
                            <div className="rcs_testi_user"> <h4>Hans Weih</h4> </div>
                        </div>
                    </Slider>
                    </div>
                 
                
                </Container>
            </div>
        </>
    )
}

export default Testimonial;